
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProviderClient } from '@/services/Services';
import ProviderGarmentProductionsTable from '../../providers/components/providerGarmentProductionsTable.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ProviderGarmentProductionsTable
    }
})
export default class SuggestionFiltersModal extends Vue {

    @Prop({
        default: ""
    }) garmentIdentifier: string;
    
    @Prop({
        default: () => new OM.BestCombinationFilters()
    }) activeFilters: OM.BestCombinationFilters;

    @Prop({
        default: true
    }) showGSMSuggestion: boolean;

    @Prop() callback: any;

    providers: OM.EditGarmentProductionsVM[] = [];
    yesOrNoSelect: OM.TextIdentifier[] = [];

    keepUsingEstimationRawMaterialsString = false.toString();
    keepUsingEstimationGSMString = false.toString();
    useProvidersString = false.toString();

    created() {
        this.yesOrNoSelect = [
            {
                identifier: true.toString(),
                text: this.$localizationService.getLocalizedValue("Yes", "Yes")
            },
            {
                identifier: false.toString(),
                text: this.$localizationService.getLocalizedValue("No", "No")
            }
        ];

        this.keepUsingEstimationRawMaterialsString = this.activeFilters.keepUsingOriginalEstimationRawMaterials.toString();
        this.useProvidersString = this.activeFilters.useUserProviders.toString();
        this.keepUsingEstimationGSMString = this.activeFilters.useSameGrams.toString();

        ProviderClient.getAllByCompanyAndGarment(this.garmentIdentifier)
        .then(x => {
            this.providers = x;
        })
    }

    keepUsingEstimationRawMaterialsUpdated(value: string){
        this.keepUsingEstimationRawMaterialsString = value;
    }
    keepUsingEstimationGSMSUpdated(value: string){
        this.keepUsingEstimationGSMString = value;
    }
    useProvidersUpdated(value: string){
        this.useProvidersString = value;
    }

    get disabled() {
        return this.useProvidersString == true.toString() && this.providers.length == 0;
    }

    addProvider() {
        this.$opModal.closeLast();
        this.$router.push('/providers');
    }

    applyFilters() {
        this.callback(this.keepUsingEstimationRawMaterialsString, this.useProvidersString, this.keepUsingEstimationGSMString);
    }

}
