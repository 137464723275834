
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ProviderGarmentProductionsTable extends Vue {

    @Prop({
        default: () => []
    }) garmentProductions: OM.EditGarmentProductionsVM[];

    @Prop({
        default: false
    }) showCTAs: boolean;

    created() {}

    editGarmentManagement(index: number) {
        this.$emit('editGarmentManagement', index);
    }
    deleteGarmentManagement(index: number) {
        this.$emit('deleteGarmentManagement', index);
    }

}
