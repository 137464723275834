import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23d4ba5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-5" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_3 = { class: "tableContainer" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createVNode(_component_localized_text, {
        type: "strong",
        localizedKey: "Production Countries",
        text: "Production Countries"
      }),
      (_ctx.providerName)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            type: "strong",
            localizedKey: "See details",
            text: "See details",
            class: "text-decoration-underline pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDetailModal()))
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedProductionCountries, (phases, layer) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: layer }, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", {
                  colspan: Object.keys(phases).length,
                  style: {"color":"#315498"}
                }, _toDisplayString(layer), 9, _hoisted_5)
              ]),
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phases, (countries, phase) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: phase,
                    style: {"font-size":"13px"}
                  }, _toDisplayString(phase), 1))
                }), 128))
              ]),
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phases, (countries, phase) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: phase,
                    style: {"font-size":"14px"}
                  }, _toDisplayString(countries[0].country), 1))
                }), 128))
              ])
            ], 64))
          }), 128))
        ])
      ])
    ])
  ]))
}