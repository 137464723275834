
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ProductionCountriesProviderDetailModal from '../modals/productionCountriesProviderDetailModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ProductionCountries extends Vue {

    @Prop({
        default: () => []
    }) countries: OM.Country_CC[];

    @Prop() providerName: string;
    @Prop() providerPrice: number;

    created() {
    }

    get groupedProductionCountries() {
        // Primo livello di raggruppamento per 'layerName'
        const groupedByLayerName = this.countries.reduce((acc, country) => {
            if (!acc[country.layerName]) {
            acc[country.layerName] = [];
            }
            acc[country.layerName].push(country);
            return acc;
        }, {});

        // Secondo livello di raggruppamento per 'productionPhase' all'interno di ogni 'layerName'
        return Object.keys(groupedByLayerName).reduce((acc, layerName) => {
            acc[layerName] = groupedByLayerName[layerName].reduce((innerAcc, country) => {
                if (!innerAcc[country.productionPhase]) {
                    innerAcc[country.productionPhase] = [];
                }
                innerAcc[country.productionPhase].push(country);
                return innerAcc;
            }, {});

            return acc;
        }, {});
    }

    openDetailModal() {
        this.$opModal.show(ProductionCountriesProviderDetailModal, {
            providerName: this.providerName,
            providerPrice: this.providerPrice,
            groupedProductionCountries: this.groupedProductionCountries
        })
    }

}
