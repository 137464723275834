import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e60f496"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { colspan: "4" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "ms-1"
}
const _hoisted_10 = { class: "d-flex align-items-start maxWithOverflow" }
const _hoisted_11 = { class: "fs_12 fw_700 d-block text-nowrap" }
const _hoisted_12 = {
  key: 0,
  class: "fs_12"
}
const _hoisted_13 = {
  key: 2,
  class: "d-flex align-items-center justify-content-end"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_ctx.showCTAs)
          ? (_openBlock(), _createElementBlock("th", _hoisted_2, [
              _createVNode(_component_localized_text, {
                localizedKey: "Garment",
                text: "Garment"
              })
            ]))
          : (_openBlock(), _createElementBlock("th", _hoisted_3, [
              _createVNode(_component_localized_text, {
                localizedKey: "Provider",
                text: "Provider"
              })
            ])),
        _createElementVNode("th", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Batch",
            text: "Batch"
          })
        ]),
        _createElementVNode("th", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Manage entire product",
            text: "Manage entire product"
          })
        ]),
        _createElementVNode("th", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "Countries",
            text: "Countries"
          })
        ]),
        (_ctx.showCTAs)
          ? (_openBlock(), _createElementBlock("th", _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_ctx.garmentProductions.length == 0)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
            _createElementVNode("td", _hoisted_6, [
              _createVNode(_component_localized_text, {
                localizedKey: "No Elements Found",
                text: "No Elements Found"
              })
            ])
          ]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.garmentProductions, (garmentManaged, index) => {
        return (_openBlock(), _createElementBlock("tr", { key: index }, [
          (_ctx.showCTAs)
            ? (_openBlock(), _createElementBlock("td", _hoisted_7, _toDisplayString(garmentManaged.garment.text), 1))
            : (_openBlock(), _createElementBlock("td", _hoisted_8, _toDisplayString(garmentManaged.provider.text), 1)),
          _createElementVNode("td", null, _toDisplayString(garmentManaged.batchCode), 1),
          _createElementVNode("td", null, [
            (garmentManaged.finalPrice > 0)
              ? (_openBlock(), _createBlock(_component_localized_text, {
                  key: 0,
                  localizedKey: "Yes",
                  text: "Yes"
                }))
              : (_openBlock(), _createBlock(_component_localized_text, {
                  key: 1,
                  localizedKey: "No",
                  text: "No"
                })),
            (garmentManaged.finalPrice > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "(" + _toDisplayString(_ctx.$filters.approximation(garmentManaged.finalPrice)) + "€)", 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(garmentManaged.phaseCountryList.filter(x => x.country != ''), (phaseCountry, phaseCountryIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass({ 'rightSeparator' : phaseCountryIndex < garmentManaged.phaseCountryList.filter(x => x.country != '').length - 1}),
                  key: phaseCountryIndex
                }, [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(phaseCountry.phase), 1),
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(phaseCountry.country) + " ", 1),
                    (phaseCountry.price > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, "(" + _toDisplayString(_ctx.$filters.approximation(phaseCountry.price)) + "€)", 1))
                      : _createCommentVNode("", true)
                  ])
                ], 2))
              }), 128))
            ])
          ]),
          (_ctx.showCTAs)
            ? (_openBlock(), _createElementBlock("td", _hoisted_13, [
                _createElementVNode("button", {
                  class: "btn btn-secondary ms-1",
                  onClick: ($event: any) => (_ctx.editGarmentManagement(index))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Edit",
                    text: "Edit"
                  })
                ], 8, _hoisted_14),
                _createElementVNode("button", {
                  class: "btn btn-primary ms-1",
                  onClick: ($event: any) => (_ctx.deleteGarmentManagement(index))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Delete",
                    text: "Delete"
                  })
                ], 8, _hoisted_15)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}