
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {}
})
export default class ProductionCountriesProviderDetailModal extends Vue {

    @Prop({
        default: ""
    }) providerName: string;
    
    @Prop({
        default: 0
    }) providerPrice: number;

    @Prop({
        default: () => {}
    }) groupedProductionCountries: any;

    created() {}

}
